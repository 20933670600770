import "./intro.css";
import Me from "../../img/me.png";
import { intro, links } from "../../data";
import { BsGithub, BsLinkedin } from "react-icons/bs";

const Intro = () => {
  return (
    <div className="i" id="intro-section">
      <div className="i-left">
        <div className="i-left-wrapper">
          <h2 className="i-intro">HI THERE 👋 I'M</h2>
          <h1 className="i-name">{intro.name}</h1>
          <div className="i-title">
            <div className="i-title-wrapper">
              <div className="i-title-item">{intro.titles.t1}</div>
              <div className="i-title-item">{intro.titles.t2}</div>
              <div className="i-title-item">{intro.titles.t3}</div>
              <div className="i-title-item">{intro.titles.t4}</div>
            </div>
          </div>

          <p className="i-desc">{intro.desc}</p>
          <div className="i-hireme">
            <a
              href="/#contact-me"
              className="i-button"
              onClick={() => {
                const element = document.getElementById("contact");
                if (element) {
                  element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }}
            >
              Hire Me
            </a>
            <div className="i-icons">
              <div className="i-icon1">
                <a href={links.linkedInLink} target="_blank" rel="noreferrer">
                  <BsLinkedin />
                </a>
              </div>

              <div className="i-icon2">
                <a href={links.gitHubLink} target="_blank" rel="noreferrer">
                  <BsGithub />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="i-right">
        <img src={Me} alt="" className="i-img" />
      </div>
    </div>
  );
};

export default Intro;
