import { about } from "../../data";
import "./about.css";

const About = () => {
  return (
    <div className="a">
      <div className="a-left">
        <div className="a-card">
          <img
            src="https://drive.google.com/uc?id=13nYvyqe-2Rip_l0544CXtNo7Plu86O0j"
            alt=""
            className="a-img"
          />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title-0">
          Who I am <span></span>
        </h1>
        <h1 className="a-title">About Me</h1>

        <p className="a-desc">
          With over 3 years of experience as a front-end developer, I possess
          strong technical skills in HTML, CSS, JavaScript, React, and Angular,
          along with experience in various frameworks and libraries. My focus is
          on developing responsive and user-friendly interfaces that provide
          seamless experiences on multiple devices.
        </p>
        <p className="a-desc">
          I am proactive, adaptable, and excel at communication, making me a
          valuable team member. Companies should hire me because of my
          self-motivation and proven track record of delivering quality work on
          time and within budget.
        </p>
        <p className="a-desc">
          My passion for creating exceptional user experiences drives me to stay
          on the cutting edge of my field. I continually seek out new ways to
          improve my skills and knowledge, and I am committed to staying
          up-to-date with the latest technologies and trends in the industry.
        </p>

        <a href={about.resumeLink} target="_blank" rel="noreferrer">
          {false && <button className="a-button">Download CV</button>}
        </a>
      </div>
    </div>
  );
};

export default About;
