export const intro = {
  name: "Shurvirsinh Sisodiya",
  titles: {
    t1: "Software Developer",
    t2: "Web Developer",
    t3: "Mobile Developer",
    t4: "Full-Stack Developer",
  },
  desc: "I'm a software developer with experience building mobile apps and websites.",
};
export const personalDetails = {
  email: "shurvirsinhsisodiya@gmail.com",
  location: "Toronto, Ontario, Canada",
};

export const about = {
  resumeLink:
    "https://drive.google.com/uc?id=1-Rf1xQoxXOLFNLvvZkAWrQgExwDKIZT-",
};
export const products = [
  {
    id: 1,
    img: "https://drive.google.com/uc?id=1iFQ7x42GxvjelwnLGQrGlcCBdP6mD31F",
    link: "https://link.shurvir.com/fashion-point",
  },
  {
    id: 2,
    img: "https://drive.google.com/uc?id=1nEEjP9xgpZL7OphWztLK2CcE0KLb44gr",
    link: "https://link.shurvir.com/linking",
  },
  {
    id: 3,
    img: "https://drive.google.com/uc?id=1Q0G1uGAEx6ukZWmazBHGmtxlNy3Zh0cb",
    link: "https://link.shurvir.com/placement-portal",
  },
  {
    id: 4,
    img: "https://drive.google.com/uc?id=1qR_G0gkPz-gJsgbqS8Z0j5jtTGTNy1ae",
    link: "https://link.shurvir.com/stock-acuity",
  },
];

export const links = {
  linkedInLink: "https://link.shurvir.com/linkedin",
  gitHubLink: "https://link.shurvir.com/github",
};
